import { IOpenDealConsumer } from './../types/IOpenDeal';
import { BaseSelectors } from '@makemydeal/dr-activities-common';
import { IDxgVehicle } from '@makemydeal/dr-shared-types';

import { IAdditionalSelectors } from '../types/IAdditionalSelectors';
import { Dealership, IOpenDeal } from '../types/IOpenDeal';
import { HostEnvironment } from '@makemydeal/dr-shared-ui-utils';

export class Selectors extends BaseSelectors {
    constructor(public sliceName: string, public additionalSelectors: IAdditionalSelectors) {
        super(sliceName);

        // TODO: Arrow Functions or specify bind statements here
        // otherwise, these functions will lose this context
    }

    /**
     * Get the slice of state for this activity
     * @param state Entire State Tree
     */
    getState = (state: any): IOpenDeal => {
        return super.getState(state);
    };

    getConsumer = (state: any): IOpenDealConsumer | undefined => {
        return this.getState(state).consumer;
    };

    getConsumerFullName = (state: any): string => {
        const stateValue = this.getState(state);
        return stateValue.consumer?.fullName || '';
    };

    getDealers = (state: any): Array<Dealership> => {
        return this.getState(state).dealers || [];
    };

    getDealership = (state: any): Dealership | undefined => {
        return this.getState(state).dealership;
    };

    getCommonOrgId = (state: any): string => {
        return this.getState(state).commonOrgId || '';
    };

    getVehicle = (state: any): IDxgVehicle | undefined => {
        return this.getState(state).vehicle;
    };

    getIsEditingVehicleSelect = (state: any): boolean => {
        return this.getState(state).isEditingVehicleSelect || false;
    };

    isOpeningDeal = (state: any): boolean => {
        return !!this.getState(state).isOpeningDeal;
    };

    isUpdateDealFailure = (state: any): boolean => {
        return !!this.getState(state).isUpdateDealFailure;
    };

    isDealModified = (state: any): boolean => {
        return !!this.getState(state).isDealModified;
    };

    isNewDeal = (state: any): boolean => {
        return !!this.getState(state).isNewDeal;
    };

    getEnvironment = (state: any): HostEnvironment | undefined => {
        return this.getState(state).environment;
    };

    isCreatingNewDeal = (state: any): boolean => {
        return !!this.getState(state).isCreatingNewDeal;
    };

    isNewDealCreatedSuccessfully = (state: any): boolean => {
        return !!this.getState(state).isNewDealCreatedSuccessfully;
    };

    isCreateNewDealFailure = (state: any): boolean => {
        return !!this.getState(state).isCreateNewDealFailure;
    };

    getManagerViewLink = (state: any): string | undefined => {
        return this.getState(state).links?.managerViewLink;
    };

    getSalesViewLink = (state: any): string | undefined => {
        return this.getState(state).links?.salesViewLink;
    };
}
