import styled from 'styled-components';

import { OpenDealCardInfo } from '../Activity.style';

export const CustomerCardContent = styled(OpenDealCardInfo)`
    margin-bottom: 7px;
`;

export const StyledCustomerCardFindOrCreateContent = styled.div`
    .interstate-x-mark-icon {
        display: none;
    }
`;
