import { RSAA } from 'redux-api-middleware';

import {
    HostEnvironment,
    OPEN_DEAL_APP_PREFIX,
    apiConfigHelper,
    locHrefUtil,
    offerSessionUtils
} from '@makemydeal/dr-shared-ui-utils';
import { IVehicle } from '@makemydeal/dr-platform-types';

import { Dealership, IOpenDealConsumer } from '../types/IOpenDeal';

import { Actions } from './actions';
import { Selectors } from './selectors';
import { getTargetApp } from '../utils';
import { RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';

export const updateDealership = (dealer: Dealership): any => {
    return {
        type: Actions.DEALERSHIP_UPDATE,
        payload: {
            ...dealer
        }
    };
};

export const switchVehicle = (vehicle: IVehicle): any => {
    return {
        type: Actions.SWITCH_VEHICLE,
        payload: {
            ...vehicle
        }
    };
};

export const toggleChangeVehicleEditMode = (): any => {
    return {
        type: Actions.TOGGLE_CHANGE_VEHICLE_EDIT_MODE
    };
};

export const openDeal = () => {
    return {
        type: Actions.OPEN_DEAL
    };
};

export const createDeal = () => {
    return {
        type: Actions.CREATE_DEAL
    };
};

export const closeOpenDealModal = () => {
    return {
        type: Actions.CLOSE_OPEN_DEAL_MODAL
    };
};

export const saveDeal = (selectors: Selectors) => (dispatch: any, getState: any) => {
    const state = getState();

    const dealXgId = offerSessionUtils.getDealXgId();
    const dealerId = selectors.getDealership(state)?.dealerId;
    const commonOrgId = selectors.getCommonOrgId(state);
    const vehicle = selectors.getVehicle(state);
    const target = getTargetApp();

    const locHref = locHrefUtil.getLocHref();
    const bffSaveDealResult = apiConfigHelper.buildBffApiUrl(OPEN_DEAL_APP_PREFIX, locHref, `deal/${dealXgId}`);
    const bffSaveDealEndpoint = bffSaveDealResult.url;

    const request = {
        endpoint: bffSaveDealEndpoint,
        method: 'PUT',
        body: JSON.stringify({
            dealerId,
            commonOrgId,
            vehicle,
            target
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        types: [Actions.SAVE_DEAL_REQUEST, Actions.SAVE_DEAL_SUCCESS, Actions.SAVE_DEAL_FAILURE]
    };

    dispatch({ [RSAA]: request });
};

export const openDealInTargetApp = (payload: any) => {
    const { links } = payload;
    const target = getTargetApp();
    const targetLink = target === 'MV' ? links.managerViewLink : links.salesViewLink;

    return {
        type: Actions.OPEN_DEAL_IN_TARGET_APP,
        payload: {
            link: targetLink
        }
    };
};

export const createNewDeal = (selectors: Selectors) => (dispatch: any, getState: any) => {
    const state = getState();

    const dealerId = selectors.getDealership(state)?.dealerId;
    const commonOrgId = selectors.getCommonOrgId(state);
    const vehicle = selectors.getVehicle(state);
    const commonConsumerId = selectors.getConsumer(state)?.commonConsumerId;
    const environment = selectors.getEnvironment(state);

    let bffNewDealEndpoint;

    if (environment) {
        const builder = apiConfigHelper.apiUrlBuilderFromEnvironment(environment, OPEN_DEAL_APP_PREFIX);
        bffNewDealEndpoint = builder.buildBffApiUrl('/deal').url;
    } else {
        const locHref = locHrefUtil.getLocHref();
        const bffSaveDealResult = apiConfigHelper.buildBffApiUrl(OPEN_DEAL_APP_PREFIX, locHref, `deal`);
        bffNewDealEndpoint = bffSaveDealResult.url;
    }

    const request: RSAACallRequest = {
        endpoint: bffNewDealEndpoint,
        method: 'POST',
        body: JSON.stringify({
            commonConsumerId,
            commonOrgId,
            dealerId,
            canDesk: true,
            vehicle
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        types: [Actions.CREATE_NEW_DEAL_REQUEST, Actions.CREATE_NEW_DEAL_SUCCESS, Actions.CREATE_NEW_DEAL_FAILURE],
        // Overriding the fetch function to ensure compatibility with the module's context.
        // When working with a federated module, the global `fetch` instance (window.fetch) may differ from the host application's instance.
        // This can cause issues with interceptors
        fetch: async (...args) => {
            return await fetch(...args);
        }
    };

    dispatch({ [RSAA]: request });
};

export const updateConsumer = (commonConsumer: IOpenDealConsumer) => {
    return {
        type: Actions.COMMON_CONSUMER_UPDATE,
        payload: {
            ...commonConsumer
        }
    };
};

export const setEnvironment = (environment: HostEnvironment) => {
    return {
        type: Actions.SET_ENVIRONMENT,
        payload: {
            environment: environment
        }
    };
};

export const closeNewDealModal = () => {
    return {
        type: Actions.CLOSE_NEW_DEAL_MODAL
    };
};
