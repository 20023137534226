// externals
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// utils
import { getTargetApp } from '../utils';

// store
import { toggleChangeVehicleEditMode, updateDealership, updateConsumer } from '../store';

// types
import { IOpenDealConsumer, IRootProps } from '../types';

// components
import { CustomerCard } from './customerCard/CustomerCard';
import { DealershipSelect } from './dealershipSelect/DealershipSelect';
import { VehicleSelect } from './vehicleSelect/VehicleSelect';
import { OpenDealHeader, StyledOpenDealActivity } from './Activity.style';
import { Footer } from './footer/Footer';
import { CommonConsumer } from '@makemydeal/dr-platform-types';

const Activity: React.FC<IRootProps> = ({ selectors }) => {
    const dealers = useSelector(selectors.getDealers);
    const vehicle = useSelector(selectors.getVehicle);
    const commonOrgId = useSelector(selectors.getCommonOrgId);
    const dealership = useSelector(selectors.getDealership);
    const isEditingVehicleSelect = useSelector(selectors.getIsEditingVehicleSelect);
    const isNewDeal = useSelector(selectors.isNewDeal);
    const consumer = useSelector(selectors.getConsumer);
    const dispatch = useDispatch();
    const [selectedDealerId, setSelectedDealerId] = useState<number | undefined>(dealership?.dealerId);
    const [consumerName, setConsumerName] = useState<string | undefined>(consumer?.fullName);

    const target = getTargetApp();
    const targetText = target === 'MV' ? 'Manager View' : 'Sales View';

    const shouldSpecifyDealership = dealers.length > 1;
    const onDealershipSelect = (dealerId: number) => {
        setSelectedDealerId(dealerId);

        const dealer = dealers.find((dealer) => dealer.dealerId === dealerId);

        if (dealer) {
            dispatch(updateDealership(dealer));
        }
    };
    const onCustomerFindOrCreate = (commonConsumer: CommonConsumer) => {
        let fullName = commonConsumer.firstName;
        if (commonConsumer.middleName) {
            fullName = `${fullName} ${commonConsumer.middleName}`;
        }
        fullName = `${fullName} ${commonConsumer.lastName}`;
        if (commonConsumer.suffix) {
            fullName = `${fullName} ${commonConsumer.suffix}.`;
        }
        setConsumerName(fullName);
        const updatedConsumer: IOpenDealConsumer = {
            fullName,
            commonConsumerId: commonConsumer.consumerId
        };
        dispatch(updateConsumer(updatedConsumer));
    };

    return (
        <StyledOpenDealActivity data-testid="dr-activities-open-deal-app">
            {!isNewDeal && (
                <OpenDealHeader>
                    <h1 className="open-deal-header-title" data-testid="open-deal-header-title">
                        Open Deal
                    </h1>
                    <div className="open-deal-header-message" data-testid="open-deal-header-message">
                        We need more information to open this deal in <span>{targetText}</span>.
                    </div>
                </OpenDealHeader>
            )}

            {shouldSpecifyDealership && (
                <DealershipSelect dealers={dealers} dealerId={selectedDealerId} onDealershipSelect={onDealershipSelect} />
            )}

            <CustomerCard
                name={consumerName}
                isNewDeal={isNewDeal}
                onCustomerFindOrCreate={onCustomerFindOrCreate}
                commonOrgId={commonOrgId}
            />

            <VehicleSelect
                selectedVehicle={vehicle}
                editMode={isEditingVehicleSelect}
                onEditModeChange={() => dispatch(toggleChangeVehicleEditMode())}
                coOrgId={commonOrgId}
            />

            <Footer />
        </StyledOpenDealActivity>
    );
};

export default Activity;
