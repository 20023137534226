/**
 * PURPOSE: Provide reducers that are required for the initial boostrap of the application.  These reducers cannot
 *   be selectively loading based on feature toggle flags.  For the the other reducers that can be conditionally
 *   added take a look at `remainingReducers.ts`.
 */

// libraries
import { navigationReducer } from '@makemydeal/dr-activities-common';
import {
    locHrefUtil,
    apiConfigHelper,
    OPEN_DEAL_APP_PREFIX,
    HostEnvironment,
    hostUrlHelper,
    LOCAL_SCHEME,
    DEV_SCHEME
} from '@makemydeal/dr-shared-ui-utils';
import { IConfigOpenDeal } from '@makemydeal/dr-activities-open-deal';
import { IConfigObj } from '@makemydeal/dr-common-utils';

// reducers
import { configReducer, sharedBootstrapReducer, featureToggleReducer } from '@makemydeal/dr-shared-store';

export const initialState: IConfigOpenDeal = {
    services: {
        bff: {
            host: '',
            protocol: ''
        }
    }
};

const createBootstrapInitialLoad = (
    logic: (appPrefix: string, environment?: HostEnvironment) => IConfigObj
): configReducer.BuildConfigReducerBootstrapInitialLoadCallback<IConfigOpenDeal> => {
    return (appPrefix, action, state) => {
        const configObj = logic(appPrefix);
        const shallowCloned = { ...state };
        shallowCloned.services = {
            ...state.services,
            bff: {
                ...state.services.bff,
                ...configObj
            }
        };

        return shallowCloned;
    };
};

const bootstrapLogicWithLocation = (appPrefix: string): IConfigObj => {
    return apiConfigHelper.buildBffApiConfig(appPrefix, locHrefUtil.getLocHref()).configObj;
};

const bootstrapLogicWithEnvironment = (appPrefix: string, environment?: HostEnvironment): IConfigObj => {
    const isLocalEnvironment = environment === HostEnvironment.Local;
    return {
        base: 'api',
        host: hostUrlHelper.getHostFromEnvironmentApp(appPrefix, environment!),
        protocol: isLocalEnvironment ? LOCAL_SCHEME : DEV_SCHEME,
        port: isLocalEnvironment ? hostUrlHelper.mapAppToLocalPortNumber(appPrefix) : undefined
    };
};

export const createInitialReducers = (environment?: HostEnvironment) => {
    const logic = environment
        ? (appPrefix: string) => bootstrapLogicWithEnvironment(appPrefix, environment)
        : bootstrapLogicWithLocation;

    return {
        sharedBootstrap: sharedBootstrapReducer.reducer,
        config: configReducer.buildConfigReducer(OPEN_DEAL_APP_PREFIX, initialState, createBootstrapInitialLoad(logic)),
        featureToggles: featureToggleReducer.reducer,
        navigation: navigationReducer
    };
};
