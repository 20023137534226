import React from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { StyledBodyContainer } from '../shells/main/routing/routes.style';
import { ActivityWrapper } from './components/ActivityWrapper';

export const NewDealForm: React.FC = () => {
    const theme = useInterstateTheme();

    return (
        <ThemeProvider theme={theme}>
            <StyleSheetManager disableCSSOMInjection>
                <StyledBodyContainer data-testid="new-deal-module-form-container">
                    <ActivityWrapper />
                </StyledBodyContainer>
            </StyleSheetManager>
        </ThemeProvider>
    );
};
