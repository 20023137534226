// externals
import { Reducer } from 'redux';

// libraries
import { initActionTypes } from '@makemydeal/dr-shared-store';

// actions
import { Actions } from './actions';

// interfaces
import { IOpenDeal } from '../types/IOpenDeal';
import { mapToDxgVehicle } from '../mappers/mapToDxgVehicle';

export const reducer: Reducer<IOpenDeal> = (state: IOpenDeal = {}, action: any) => {
    const { payload, type } = action;

    switch (type) {
        case initActionTypes.INIT_PENCIL_SUCCESS: {
            const dealership = payload.dealers?.length === 1 ? payload.dealers[0] : undefined;
            return {
                ...state,
                commonOrgId: payload.commonOrgId,
                dealers: payload.dealers,
                consumer: payload.consumer,
                vehicle: payload.vehicle,
                isDealModified: false,
                dealership,
                isNewDeal: !payload.consumer,
                links: {}
            };
        }
        case Actions.DEALERSHIP_UPDATE: {
            return {
                ...state,
                dealership: payload,
                isDealModified: true
            };
        }
        case Actions.SWITCH_VEHICLE: {
            return {
                ...state,
                vehicle: mapToDxgVehicle(payload),
                isDealModified: true
            };
        }
        case Actions.TOGGLE_CHANGE_VEHICLE_EDIT_MODE: {
            return {
                ...state,
                isEditingVehicleSelect: !state.isEditingVehicleSelect
            };
        }
        case Actions.OPEN_DEAL: {
            return {
                ...state,
                isOpeningDeal: true,
                isUpdateDealFailure: false
            };
        }
        case Actions.CLOSE_OPEN_DEAL_MODAL: {
            return {
                ...state,
                isUpdateDealFailure: false
            };
        }
        case Actions.SAVE_DEAL_FAILURE: {
            return {
                ...state,
                isOpeningDeal: false,
                isUpdateDealFailure: true
            };
        }
        case Actions.COMMON_CONSUMER_UPDATE: {
            return {
                ...state,
                consumer: payload,
                isDealModified: true
            };
        }
        case Actions.SET_ENVIRONMENT: {
            return {
                ...state,
                environment: payload.environment
            };
        }
        case Actions.CREATE_NEW_DEAL_REQUEST: {
            return {
                ...state,
                isCreatingNewDeal: true,
                isCreateNewDealFailure: false
            };
        }
        case Actions.CREATE_NEW_DEAL_SUCCESS: {
            return {
                ...state,
                isNewDealCreatedSuccessfully: true,
                links: {
                    ...action.payload.links
                }
            };
        }
        case Actions.CREATE_NEW_DEAL_FAILURE: {
            return {
                ...state,
                isCreatingNewDeal: false,
                isCreateNewDealFailure: true
            };
        }
        case Actions.CLOSE_NEW_DEAL_MODAL: {
            return {
                ...state,
                isCreateNewDealFailure: false
            };
        }
        default: {
            return state;
        }
    }
};
