import React from 'react';

export const NotAuthenticated: React.FC = () => {
    return (
        <div data-testid="not-authenticated-container">
            <h1>Access Denied</h1>
            <p>Your session has expired. To continue, please log in again.</p>
        </div>
    );
};
