import { Activity } from '@makemydeal/dr-activities-open-deal';
import { useSelector } from 'react-redux';
import { getOpenDealConfigFromState, getOpenDealSelectors } from '../../shells/main/activities/open-deal-dashboard/config';
import { SuccessComponent } from './SuccessComponent';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { ArrowTopRightOnSquareIcon } from '@interstate/components/Icons';
import { StyledLink, StyledLinkLinkContainer } from './ActivityWrapper.style';

export const ActivityWrapper: React.FC = () => {
    const theme = useInterstateTheme();
    const config = useSelector(getOpenDealConfigFromState);
    const selectors = getOpenDealSelectors();
    const actionsCreators = {} as any;
    const isNewDealCreatedSuccessfully = useSelector(selectors.isNewDealCreatedSuccessfully);
    const managerViewLink = useSelector(selectors.getManagerViewLink);
    const salesViewLink = useSelector(selectors.getSalesViewLink);

    return (
        <>
            <h1 className="open-deal-header-title" data-testid="open-deal-header-title">
                Create Deal
            </h1>
            {isNewDealCreatedSuccessfully ? (
                <>
                    <SuccessComponent selectors={selectors} />
                    <StyledLinkLinkContainer>
                        {managerViewLink && (
                            <StyledLink href={managerViewLink}>
                                <ArrowTopRightOnSquareIcon data-testid="card-check-icon" color={theme.tokens.BaseColorBlue700} /> Go
                                To Deal In Manager View
                            </StyledLink>
                        )}
                        {salesViewLink && (
                            <StyledLink href={salesViewLink}>
                                <ArrowTopRightOnSquareIcon data-testid="card-check-icon" color={theme.tokens.BaseColorBlue700} /> Go
                                To Deal In Sales View
                            </StyledLink>
                        )}
                    </StyledLinkLinkContainer>
                </>
            ) : (
                <Activity actionCreators={actionsCreators} config={config} baseRoute="/" selectors={selectors} />
            )}
        </>
    );
};
