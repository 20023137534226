// libraries
import { GetCashOfferAdditionalSelectors } from '@makemydeal/dr-offer-redux';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// selectors
import * as offerReduxSelectors from './offerRedux';
import { getTotalMvVppSelectionPrice } from './vehicleProtectionSelectors';
import { getTotalMvAccessoriesSelectionPrice } from './accessoriesSelectors';
import { getShouldIncludeTradeIn } from './tradeInCardSelectors';

export const getMvCashOfferAdditionalSelectors = (state: StateTree): GetCashOfferAdditionalSelectors => {
    return {
        getTotalVppSelectionPrice: getTotalMvVppSelectionPrice,
        getTotalAccessoriesSelectionPrice: getTotalMvAccessoriesSelectionPrice,
        getIsTradeIncluded: getShouldIncludeTradeIn
    };
};

export const getMvCashOfferRoundedUp = (state: StateTree, showDecimal?: boolean): number => {
    const mvAdditionalSelectors = getMvCashOfferAdditionalSelectors(state);
    return offerReduxSelectors.getCashOfferRoundedUp(state, mvAdditionalSelectors, showDecimal);
};

export const getMvCashAmountDue = (state: StateTree) => {
    const isUsePSTotalAmountDueForCashDealsEnabled = featureToggleSelectors.isUsePSTotalAmountDueForCashDealsEnabled(state);
    const cashOffer = getMvCashOfferRoundedUp(state, true);
    const totalAmountDueAfterCredits = offerReduxSelectors.getCashTotalAmountDueAfterCredits(state);
    return isUsePSTotalAmountDueForCashDealsEnabled ? totalAmountDueAfterCredits : cashOffer;
};
