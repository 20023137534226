// istanbul ignore file  # Not needed to test this file
import { loadRemoteModule } from '@makemydeal/dr-shared-ui-utils';
import { ComponentType, LazyExoticComponent, lazy } from 'react';

export type FindOrCreateProps = {
    viewportMode: string;
    environment: string;
    dealershipId: string;
    onFindOrCreate: (consumer: any) => void;
    getAuthOverride: () => string;
    requiredFields?: string[];
    header: { title: string };
};

export const FindOrCreateModule: LazyExoticComponent<ComponentType<FindOrCreateProps>> = lazy(() =>
    loadRemoteModule('customer', './FindOrCreateModule')
);
