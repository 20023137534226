import { createSelector } from 'reselect';
import { isEqual } from 'lodash';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { DealHistory, StateTree } from '@makemydeal/dr-dash-types';
import { IDealerFee as DealerFee } from '@makemydeal/dr-platform-shared';
import {
    CASH,
    ItemizedGovernmentFee,
    MenuProductsPayloadOfferDetails,
    IOffer as Offer,
    RegionBreakdown,
    ResidualInfo,
    TaxBreakdown,
    IVehicle as Vehicle
} from '@makemydeal/dr-platform-types';
import { DefaultRootState } from '@makemydeal/dr-react-18-wrappers';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import * as constants from '../constants';

// selectors
import {
    getAcqFeeOverride,
    getAcqFeeUpFrontOverride,
    getAnnualMiles,
    getBuyRateOverride,
    getCreditTiers,
    getCurrentOfferType,
    getDaysToFirstPayment,
    getDealerFees as getDealerFeesOR,
    getDealerFeesTotalFromTerm,
    getDmvFeesFromTerm,
    getDownPaymentByOfferType,
    getGovernmentFees,
    getIncentivesTotalOverride,
    getIsUserProgramQuotes,
    getMileageChargeOverride,
    getMonthlyPaymentByOfferType,
    getMonthlyPaymentRoundedByOfferType,
    getCapReductionRebate as getOfferCapReductionRebate,
    getCapReductionTradeIn as getOfferCapReductionTradeIn,
    getOfferCreationDateString,
    getOfferDetails,
    getOfferFlatTaxItem,
    getNetCashDown as getOfferNetCashDown,
    getOfferPriceByOfferType,
    getPaymentProducts,
    getRateType,
    getResidualInfoOverride,
    getRetailPriceOverride,
    getRetailPriceOverrideByOfferType,
    getTaxItemsWithOverridesWithoutFlat,
    getSecurityDepositOverride,
    getSelectedCreditTierIdByType,
    getSelectedTerm,
    getSellRateOverride,
    getShopperInfo,
    getTaxBreakdown,
    getTermIdByOfferType,
    isOfferFromVinSource,
    getDealerAcquisitionFeeUpfrontByOfferType,
    getDealerAcquisitionFeeAmountByOfferType,
    hasManualTotalTax,
    getItemizedLenderFees as getLenderFees
} from './offerRedux';
import { getPaymentLocationId } from './payment';
import { getTradeIn } from './trade';

// consts/enums
import { ACQUISITION_FEE_ID } from '../constants';

export const getOfferState = (state: StateTree): Offer => {
    return state.offer || ({} as Offer);
};

export const getLeaseOutOfPocket = (state: StateTree) => {
    const selectedTerm = getSelectedTerm(state, 'lease');
    return selectedTerm.outOfPocket;
};

export const getWorkingOffer = (state: StateTree): DealHistory => {
    return {
        accessories: state.accessories || {},
        bootstrap: state.bootstrap || {},
        dealer: state.dealer || {},
        featureToggles: state.featureToggles || {},
        offer: state.offer || ({} as Offer),
        sharedBootstrap: state.sharedBootstrap || {},
        tradeIn: getTradeIn(state),
        vehicle: state.vehicle || ({} as Vehicle),
        vehicleProtection: state.vehicleProtection || {},
        vehicleProtectionV2: state.vehicleProtectionV2
    } as DealHistory;
};

export const hasDealBeenAccepted = (state: StateTree): boolean => {
    return getOfferState(state).accepted || false;
};

export const isOfferFromExternalSource = (state: StateTree): boolean => {
    // potentially other integrations can be grouped under this selector
    return isOfferFromVinSource(state);
};

/**
 * Internally retrieves the current offer type and in turn the relevant exact monthly payment
 */
export const getMonthlyPayment = (state: StateTree): number => {
    const currentOfferType = getCurrentOfferType(state);
    return getMonthlyPaymentByOfferType(state, currentOfferType) || 0;
};

/**
 * Internally retrieves the current offer type and in turn the relevant rounded monthly payment
 */
export const getMonthlyPaymentRounded = (state: StateTree): number => {
    const currentOfferType = getCurrentOfferType(state);
    return getMonthlyPaymentRoundedByOfferType(state, currentOfferType);
};

export const getPaymentTermsFallback = (state: StateTree) => {
    const { paymentTermsFallback } = getOfferState(state);
    return paymentTermsFallback;
};

export const getDealerFees = (state: StateTree): DealerFee[] => {
    const currentOfferType = getCurrentOfferType(state);
    if (currentOfferType === CASH) {
        return (getOfferDetails(state, CASH)?.dealerFees || []) as DealerFee[];
    } else {
        const dealerFeeSum = getDealerFeesOR(state);
        return (dealerFeeSum || []) as DealerFee[];
    }
};

export type DealerFeeWithOriginalCategory = DealerFee & {
    originalCategory: string;
    isManualFee: boolean;
    isFeeIncludedInCalculation?: boolean;
    includedInCalc?: boolean;
};

export const getItemizedLenderFees = (state: StateTree) => {
    const dealerFees = getDealerFees(state) as DealerFeeWithOriginalCategory[];
    return dealerFees.filter((fee) => {
        return fee.originalCategory?.toLowerCase() === 'lender';
    });
};

export const getIncludedCappedLenderFees = (state: StateTree): DealerFee[] => {
    const lenderFees = getItemizedLenderFees(state);
    return lenderFees.filter((fee) => fee.dealerFeeCapped && (fee.isFeeIncludedInCalculation || fee.includedInCalc));
};

export const getItemizedGovernmentFees = (state: StateTree) => {
    const govFees = getGovernmentFees(state);
    return govFees?.fee?.taxFee || ([] as ItemizedGovernmentFee[]);
};
export type GovernmentFeeWithIncluded = ItemizedGovernmentFee & {
    isFeeIncludedInCalculation?: boolean;
};
export const getIncludedCappedGovernmentFees = (state: StateTree): ItemizedGovernmentFee[] => {
    const governmentFees = getItemizedGovernmentFees(state);
    return governmentFees.filter(
        (fee: GovernmentFeeWithIncluded) => fee.capped && (fee.isFeeIncludedInCalculation || fee.includedInCalc)
    );
};

export const getItemizedDealerFees = (state: StateTree) => {
    let dealerFees = [] as DealerFeeWithOriginalCategory[];
    const currentOfferType = getCurrentOfferType(state);
    if (currentOfferType === CASH) {
        dealerFees = (getOfferDetails(state, CASH)?.dealerFees || []) as any;
    } else {
        const dealerFeeSum = getDealerFeesOR(state);
        dealerFees = (dealerFeeSum || []) as any;
    }

    const filteredFees = dealerFees.filter(
        (fee) => fee.originalCategory?.toLowerCase() === 'dealer' && fee.dealerFeeTypeCode !== ACQUISITION_FEE_ID
    );
    return filteredFees;
};
export const getIncludedCappedDealerFees = (state: StateTree): DealerFee[] => {
    const dealerFees = getItemizedDealerFees(state);
    return dealerFees.filter((fee) => fee.dealerFeeCapped && (fee.isFeeIncludedInCalculation || fee.includedInCalc));
};

export const getCombinedItemizedFees = (state: StateTree) => {
    return [...getLenderFees(state), ...getItemizedDealerFees(state), ...getItemizedGovernmentFees(state)];
};

export const getFlatOrItemizedTaxes = (state: StateTree) => {
    if (hasManualTotalTax(state)) {
        const flatTaxItem = getOfferFlatTaxItem(state);

        const advancedFlatTax = {
            ...flatTaxItem,
            taxName: 'Advanced Flat Tax'
        };
        return flatTaxItem ? [advancedFlatTax] : [];
    }
    return getTaxItemsWithOverridesWithoutFlat(state);
};

export const getFlatTaxForDisplay = (state: StateTree) => {
    const flatTaxItem = getOfferFlatTaxItem(state);

    const advancedFlatTax = {
        ...flatTaxItem,
        taxName: 'Advanced Flat Tax'
    };
    return flatTaxItem ? [advancedFlatTax] : [];
};

export const getSelectedCreditTier = (state: StateTree) => {
    const type = getCurrentOfferType(state);
    const selected = getSelectedCreditTierIdByType(state, type);
    return selected;
};

export const getCreditTiersAsOptions = createSelector(
    getCreditTiers,
    (tiers) => tiers.map((tier: any) => ({ value: tier.id, label: tier.name })),
    {
        memoizeOptions: {
            resultEqualityCheck: isEqual
        }
    }
);

export const getDealerFeeTotal = (state: StateTree): number => {
    const currentOfferType = getCurrentOfferType(state);
    if (currentOfferType === CASH) {
        return getOfferDetails(state, CASH)?.dealerFeesTotal || 0;
    } else {
        const dealerFeeSum = getDealerFeesTotalFromTerm(getSelectedTerm(state));
        return dealerFeeSum || 0;
    }
};

export const getDmvFeeTotal = (state: StateTree): number => {
    const currentOfferType = getCurrentOfferType(state);
    if (currentOfferType === CASH) {
        return getOfferDetails(state, CASH)?.dmvFees || 0;
    } else {
        const dmvFeesTotal = getDmvFeesFromTerm(getSelectedTerm(state));
        return dmvFeesTotal || 0;
    }
};

export const getDmvFeeList = (state: StateTree): any[] => {
    const governmentFee = getGovernmentFees(state);
    return governmentFee && governmentFee.fee.taxFee ? governmentFee.fee.taxFee : [];
};

export const getAmountFinanced = (state: StateTree) => {
    const selectedTerm = getSelectedTerm(state);
    return selectedTerm?.amountFinanced || 0;
};

export const getDownPayment = (state: StateTree): number => {
    const offerType = getCurrentOfferType(state);
    return getDownPaymentByOfferType(state, offerType) || 0;
};

export const getSellingPrice = (state: StateTree) => {
    const currentOfferType = getCurrentOfferType(state);
    return getOfferPriceByOfferType(state, currentOfferType) || 0;
};

enum PaymentFrequency {
    MONTHLY = 'Monthly'
}
export const getPaymentFrequency = (state: StateTree): PaymentFrequency => PaymentFrequency.MONTHLY;

export const getSelectedTermMonths = (state: StateTree): number | undefined => {
    const currentOfferType = getCurrentOfferType(state);
    const termId = getTermIdByOfferType(state, currentOfferType);
    if (!termId) {
        return undefined;
    }
    const parts = termId.split('_');
    return Number(parts[2]);
};

export const isValidValue = (value: number): boolean => Boolean(value) && !isNaN(value) && value >= 1;

export const isPaymentUnavailable = (state: StateTree): boolean => {
    const currentOfferType = getCurrentOfferType(state);
    const offerPrice = getSellingPrice(state);
    const monthlyPayment = getMonthlyPayment(state);
    const isCash = currentOfferType === CASH;

    const paymentHasErrored = isCash ? !isValidValue(offerPrice) : !isValidValue(monthlyPayment);
    return paymentHasErrored;
};

export const getMonthlyUseTaxBreakdown = (state: DefaultRootState): RegionBreakdown | undefined => {
    const taxBreakdown = getTaxBreakdown(state) || ({} as TaxBreakdown);
    return taxBreakdown.monthlyUseBreakdown;
};
export const getMonthlyUseTaxTotal = (state: DefaultRootState): number => {
    const taxBreakdown = getTaxBreakdown(state) || ({} as TaxBreakdown);
    return taxBreakdown.monthlyUseTotal || 0;
};
export const getPrimaryTaxTotal = (state: DefaultRootState): number => {
    const taxBreakdown = getTaxBreakdown(state) || ({} as TaxBreakdown);
    return taxBreakdown.primaryTotal;
};
export const getAdditionalTaxBreakdown = (state: DefaultRootState): RegionBreakdown => {
    const taxBreakdown = getTaxBreakdown(state) || ({} as TaxBreakdown);
    return taxBreakdown.additionalBreakdown;
};
export const getAdditionalTaxTotal = (state: DefaultRootState): number => {
    const taxBreakdown = getTaxBreakdown(state) || ({} as TaxBreakdown);
    return taxBreakdown.additionalTotal;
};

export const getShopperEmail = (state: StateTree): string => {
    const shopper = getShopperInfo(state);
    return shopper.email;
};

export const getShopperLastName = (state: StateTree): string => {
    const { lastName } = getShopperInfo(state);
    return lastName || '';
};

export const getUpfrontFeeBreakDown = (state: StateTree): any[] => {
    const dealerFees = getDealerFees(state);
    const dmvFees = getDmvFeeList(state);
    const itemizedUpfronts: any[] = [];
    dealerFees.forEach((fee) => {
        const { dealerFeeCapped, dealerFeeDesc, dealerFeeAmount } = fee;
        if (!dealerFeeCapped) {
            itemizedUpfronts.push({
                label: dealerFeeDesc,
                amount: formatDollarsAndCents(dealerFeeAmount)
            });
        }
    });
    dmvFees.forEach((fee) => {
        const { capped, description, amount } = fee;
        if (!capped) {
            itemizedUpfronts.push({
                label: description,
                amount: formatDollarsAndCents(amount)
            });
        }
    });
    return itemizedUpfronts;
};

export function getNetCashDown(state: StateTree): number {
    return getOfferNetCashDown(state) || 0;
}

export function getCapReductionRebate(state: StateTree): number {
    return getOfferCapReductionRebate(state) || 0;
}

export function getCapReductionTradeIn(state: StateTree): number {
    return getOfferCapReductionTradeIn(state) || 0;
}

export const getAvailableFinanceTermIds = (state: StateTree): string[] => {
    return state.offer?.terms.financeTerms || [];
};
export const getAvailableLeaseTermIds = (state: StateTree): string[] => {
    return state.offer?.terms.leaseTerms || [];
};

export function getlenderFeeEditToggle(state: StateTree): boolean {
    return state.featureToggles?.enableLenderFeeEdits || false;
}

export function getAcqFeeOverrideWithFallback(state: StateTree): number {
    const lenderFeeEditToggle = state.featureToggles?.enableLenderFeeEdits;
    if (lenderFeeEditToggle) {
        const offerType = getCurrentOfferType(state);
        return getDealerAcquisitionFeeAmountByOfferType(state, offerType);
    }

    return getAcqFeeOverride(state, true);
}

export function getAcqFeeUpFrontOverrideWithFallback(state: StateTree): boolean {
    const lenderFeeEditToggle = state.featureToggles?.enableLenderFeeEdits;
    if (lenderFeeEditToggle) {
        const offerType = getCurrentOfferType(state);
        return getDealerAcquisitionFeeUpfrontByOfferType(state, offerType);
    }
    return getAcqFeeUpFrontOverride(state, true);
}

export function getDaysToFirstPaymentWithFallback(state: StateTree): number {
    return getDaysToFirstPayment(state) || constants.DEFAULT_DAYS_TO_FIRST_PAYMENT;
}

export function getRetailPriceOverrideWithFallback(state: StateTree): number {
    const value = getRetailPriceOverride(state, true);
    return value;
}

export function getSecurityDepositOverrideWithFallback(state: StateTree): number {
    return getSecurityDepositOverride(state, true);
}

export function getSellRateOverrideWithFallback(state: StateTree): number {
    return getSellRateOverride(state, true) ?? 0;
}
export function getBuyRateOverrideWithFallback(state: StateTree): number {
    return getBuyRateOverride(state, true) ?? 0;
}
export function getMileageChargeOverrideWithFallback(state: StateTree) {
    return getMileageChargeOverride(state, true);
}
export function getResidualInfoOverrideWithFallback(state: StateTree): ResidualInfo {
    return getResidualInfoOverride(state, true);
}

export function getIncentivesTotalOverrideWithFallback(state: StateTree): number {
    return getIncentivesTotalOverride(state) ?? 0;
}

export function getDealRefId(state: StateTree): string {
    if (featureToggleSelectors.isToggleFsDealRefIdEnabled(state)) {
        // Finance Services Deal Ref Id
        return state.offer?.fsDealRefId || state.offerInfo.fsDealId || '';
    } else {
        // Shopper Quote Deal Ref Id
        return state.offer?.dealRefId || state.offerInfo.dealRefId || '';
    }
}

export const getCappedDealerFeesTotal = (state: StateTree): number => {
    const dealerFees = getDealerFees(state);
    return dealerFees.reduce((total: number, dealerFee: DealerFee): number => {
        if (dealerFee.dealerFeeCapped) {
            return total + dealerFee.dealerFeeAmount;
        }
        return total;
    }, 0);
};

export const getOfferDetailsForMenu = (state: StateTree): MenuProductsPayloadOfferDetails => {
    const selectedTerm = getSelectedTerm(state);
    const offerType = getCurrentOfferType(state);
    return {
        apr: selectedTerm?.apr,
        offerType,
        term: selectedTerm?.months,
        totalFinanced: selectedTerm?.amountFinanced,
        createdOn: getOfferCreationDateString(state),
        offerPrice: getOfferPriceByOfferType(state, offerType),
        retailPrice: getRetailPriceOverrideByOfferType(state, offerType),
        monthlyPayment: getMonthlyPayment(state),
        annualMiles: getAnnualMiles(state),
        locationId: getPaymentLocationId(state),
        paymentProducts: getPaymentProducts(state, offerType)
    };
};

/**
 * Retrieve the rate type from either the selected term or the offer type
 * @param state
 * @returns
 */
export const getRateTypeOverrideFromOfferTypeOrSelectedTerm = (state: any) => {
    const offerType = getCurrentOfferType(state);
    const creditDecisionEnabled = getIsUserProgramQuotes(state);
    if (creditDecisionEnabled) {
        const offerDetails = getOfferDetails(state, offerType);
        return offerDetails.rateTypeOverride;
    }
    return getRateType(state);
};

export const getOfferGlobalCustomerId = (state: StateTree) => {
    return getOfferState(state).globalCustomerId || '';
};

export const getOfferCommonConsumerId = (state: StateTree) => {
    return getOfferState(state).commonConsumerId || '';
};

export const getTotalAllowance = (state: StateTree) => {
    const term = getSelectedTerm(state);
    return term.totalAllowance ?? 0;
};
