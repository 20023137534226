import { FC, Suspense, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Card } from '@interstate/components/Card';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { CommonConsumer } from '@makemydeal/dr-platform-types';

import { OpenDealCardHeader } from '../openDealCardHeader/OpenDealCardHeader';
import { FindOrCreateModule } from './FindOrCreateModule';
import { CustomerCardContent, StyledCustomerCardFindOrCreateContent } from './CustomerCard.style';

const enum environments {
    nonprod = 'nonprod',
    prod = 'prod'
}

const nonprodEnvs = ['localhost', 'np.', 'np-', 'dev', 'dev2', 'qa'];

export const deriveEnvironmentName = () => {
    const url = window.location.href;
    return nonprodEnvs.some((x) => url.includes(x)) ? environments.nonprod : environments.prod;
};

export type CustomerCardProps = {
    name?: string;
    onCustomerFindOrCreate: (consumer: CommonConsumer) => void;
    commonOrgId: string;
    isNewDeal: boolean;
};

export const CustomerCard: FC<CustomerCardProps> = ({
    name,
    onCustomerFindOrCreate,
    commonOrgId,
    isNewDeal
}: CustomerCardProps) => {
    const theme = useInterstateTheme();
    const requiredFields: string[] = [];
    const [editMode, setEditMode] = useState(() => false);
    const oktaContext = useOktaAuth();
    const getJwtToken = () => oktaContext.oktaAuth.getAccessToken() || '';
    const cardHeaderTitle = name ? 'Customer' : 'Customer (required)';
    const cardHeaderAction = isNewDeal ? (editMode ? 'Close' : name ? 'Edit' : 'Start') : undefined;
    const isComplete = !!name;
    const onFindOrCreate = (consumer: CommonConsumer) => {
        onCustomerFindOrCreate(consumer);
        setEditMode(!editMode);
    };
    const renderContent = () => {
        if (isNewDeal && editMode) {
            return (
                <StyledCustomerCardFindOrCreateContent>
                    <Suspense fallback={<div>Loading...</div>}>
                        <FindOrCreateModule
                            dealershipId={commonOrgId}
                            environment={deriveEnvironmentName()}
                            header={{ title: 'Add Customer' }}
                            requiredFields={requiredFields}
                            viewportMode={'sidebar'}
                            onFindOrCreate={onFindOrCreate}
                            getAuthOverride={getJwtToken}
                        />
                    </Suspense>
                </StyledCustomerCardFindOrCreateContent>
            );
        } else if (name) {
            return <CustomerCardContent data-testid="customer-card-name">{name}</CustomerCardContent>;
        }
        return null;
    };

    return (
        <Card
            sx={{ backgroundColor: theme.tokens.BaseColorGray50 }}
            data-testid="customer-card"
            header={
                <OpenDealCardHeader
                    data-testid="customer-card-header"
                    title={cardHeaderTitle}
                    showIcon
                    isComplete={isComplete}
                    action={cardHeaderAction}
                    onActionClick={() => setEditMode(!editMode)}
                />
            }
            content={renderContent()}
        ></Card>
    );
};
