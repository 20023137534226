import React from 'react';

import { Selectors } from '@makemydeal/dr-activities-open-deal';
import { Alert } from '@interstate/components/Alert';

import { useSelector } from 'react-redux';
export interface SuccessComponentProps {
    selectors: Selectors;
}

export const SuccessComponent: React.FC<SuccessComponentProps> = ({ selectors }) => {
    const customerName = useSelector(selectors.getConsumer)?.fullName ?? '';
    const vehicle = useSelector(selectors.getVehicle);
    const successMessage = `${customerName}'s deal on a ${vehicle?.year} ${vehicle?.make} ${vehicle?.model} ${vehicle?.trim} has been saved.`;
    return (
        <Alert type="success" data-testid="success-new-deal-created-alert" title="Deal Saved Successfully" disableSuccessFade>
            <span>{successMessage}</span>
        </Alert>
    );
};
