import styled from 'styled-components';

export const StyledLink = styled.a`
    align-items: center;
    color: #005ba8;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    gap: 8px;
    margin-right: 8px;
    padding: 10px;
`;

export const StyledLinkLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
