// externals
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// libraries
import { BootstrapLoadingState } from '@makemydeal/dr-shared-types';
import { sharedBootstrapSelectors } from '@makemydeal/dr-shared-store';
import { HostEnvironment, loadingIndicatorUtils, OPEN_DEAL_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

import { initialLoad, fetchBootstrapData } from '../../actions/bootstrapAppActionCreators';

// components
import { LoadingPage } from '../loadingPage/LoadingPage';
import { BootstrapError } from '../bootstrapError/BootstrapError';

export interface BootstrapperProps extends React.PropsWithChildren {
    environment?: HostEnvironment;
    coOrgId?: string;
}

export const Bootstrapper: React.FC<BootstrapperProps> = ({ children, environment, coOrgId }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const loadingState = useSelector(sharedBootstrapSelectors.getBootstrapLoadingState);
    const loadingErrorMessage = useSelector(sharedBootstrapSelectors.getBootstrapErrorMessage);
    const hasInitialized = useRef(false);

    useEffect(() => {
        // load the deal props
        if (!hasInitialized.current) {
            dispatch(initialLoad());
            // fetch the deal
            dispatch(fetchBootstrapData(OPEN_DEAL_APP_PREFIX, environment, coOrgId));
            hasInitialized.current = true;
        }
    }, [dispatch, environment, coOrgId]); // even though dispatch is a stable reference, it is added to keep the linter happy

    useEffect(() => {
        if (loadingState !== BootstrapLoadingState.LoadedSuccessfully && loadingState !== BootstrapLoadingState.LoadFailed) return;

        loadingIndicatorUtils.removeAllLoadingIndicatorPanels();
    }, [loadingState]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0
        });
    }, [location]);

    if (loadingErrorMessage) return <BootstrapError loadingError={loadingErrorMessage} />;

    return (
        <>
            {loadingState === BootstrapLoadingState.Loading && <LoadingPage />}
            {loadingState === BootstrapLoadingState.LoadedSuccessfully && children}
            {loadingState === BootstrapLoadingState.LoadFailed && <BootstrapError />}
            {loadingState.toString() === '' && 'Error occurred bootstrapping the app - unsupport state.'}
        </>
    );
};
