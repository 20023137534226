import { useRef } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { GlobalStyle } from '../globalStyle/GlobalStyle.style';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { configureStore } from '@makemydeal/dr-shared-store';
import { getInitialMiddleware } from '../shells/main/store/initialMiddleware';
import { getRemainingMiddleware } from '../shells/main/store/remainingMiddleware';
import { setOktaAuthForInterceptors, setupAuthNetworkInterceptsForModule } from '@makemydeal/dr-shared-network';
import OktaAuth from '@okta/okta-auth-js';
import { MemoryRouter } from '@makemydeal/dr-react-18-wrappers';
import { Routes } from './components/Routes';
import { apiConfigHelper, HostEnvironment, OPEN_DEAL_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { Bootstrapper } from '../shells/main/components/bootstrapper/Bootstrapper';
import { createInitialReducers } from '../shells/main/reducers/initialReducers';

export interface NewDealModuleProps {
    commonOrgId: string;
    oktaAuth: OktaAuth;
    environment: HostEnvironment;
}

const state = {};
const allMiddleware = getInitialMiddleware().concat(getRemainingMiddleware());
let isInterceptorsInitialized = false;

function initializeNetworkInterceptors(environment: HostEnvironment, oktaAuth: OktaAuth) {
    if (!isInterceptorsInitialized) {
        setupAuthNetworkInterceptsForModule(apiConfigHelper.apiUrlBuilderFromEnvironment(environment, OPEN_DEAL_APP_PREFIX));
        setOktaAuthForInterceptors(oktaAuth);
        isInterceptorsInitialized = true;
    }
}

export function NewDealModule({ oktaAuth, environment, commonOrgId }: NewDealModuleProps) {
    const storeRef = useRef(configureStore(state, allMiddleware, createInitialReducers(environment)));
    initializeNetworkInterceptors(environment, oktaAuth);

    return (
        isInterceptorsInitialized &&
        storeRef.current && (
            <>
                <GlobalStyle />
                <InterstateThemeProvider
                    themeName="Interstate"
                    applicationName="Open Deal"
                    scopeName="Open Deal"
                    themeRegistries={[interstateThemeRegistry]}
                >
                    <ReduxProvider store={storeRef.current}>
                        <MemoryRouter>
                            <Bootstrapper environment={environment} coOrgId={commonOrgId}>
                                <Routes oktaAuth={oktaAuth} environment={environment} />
                            </Bootstrapper>
                        </MemoryRouter>
                    </ReduxProvider>
                </InterstateThemeProvider>
            </>
        )
    );
}

export default NewDealModule;
