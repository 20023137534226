import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeNewDealModal, selectors } from '../../../store';
import { StyledFooterCancelContainer } from '../Footer.style';
import { LoadingIndicatorWrapper } from './LoadingIndicatorWrapper.style';

import { Button } from '@interstate/components/Button';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { Modal } from '@interstate/components/Modal';
import { Typography } from '@interstate/components/Typography';

export const CreateNewDealDialog: React.FC = () => {
    const dispatch = useDispatch();
    const isCreatingNewDeal = useSelector(selectors.isCreatingNewDeal);
    const isCreateNewDealFailure = useSelector(selectors.isCreateNewDealFailure);

    const handleCloseModal = () => dispatch(closeNewDealModal());

    const footerComponent = isCreateNewDealFailure ? (
        <StyledFooterCancelContainer>
            <Button data-testid="create-new-deal-dialog-close-btn" buttonStyle="secondary" onClick={handleCloseModal}>
                Close
            </Button>
        </StyledFooterCancelContainer>
    ) : (
        <LoadingIndicatorWrapper>
            <LoadingIndicator size="small" data-testid="create-new-deal-dialog-loading-indicator" />
        </LoadingIndicatorWrapper>
    );

    const textContent = isCreateNewDealFailure
        ? `Creating a deal failed`
        : `Creating a deal. Please hold on while we save your deal.`;

    const content = <Typography variant="body-md">{textContent}</Typography>;

    return (
        <Modal
            show={isCreatingNewDeal || isCreateNewDealFailure}
            size="small"
            data-testid="create-new-deal-dialog"
            header={
                <Typography variant="h3" color="base.color.blue.900">
                    Creating new deal
                </Typography>
            }
            sx={{
                '.ids-modal-fixed-footer-component': {
                    padding: '20px 24px 24px'
                }
            }}
            footer={{ footerComponent }}
        >
            {content}
        </Modal>
    );
};
